import type { ComponentType } from "react"
import { useEffect } from "react"

import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"

const useStore = createStore({
    values: [],
    locked: false,
})

export function valueTable(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()
        return (
            <Component
                {...props}
                onChange={(v) => {
                    setStore({ values: v })
                }}
                onLocked={(v) => {
                    setStore({ locked: v })
                }}
            />
        )
    }
}

export function valueVoter(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        return (
            <Component {...props} values={store.values} locked={store.locked} />
        )
    }
}
